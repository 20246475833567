var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "560px",
        center: "",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": true,
        "custom-class": "emailAddressBookDialog",
        top: "0"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogBody" }, [
        _c("span", {
          staticClass: "iconfont_Me icon-x closeDialogStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        }),
        _c("div", { staticClass: "dialogHeader" }, [
          _vm._v("Melinked " + _vm._s(_vm.$t("contacts")))
        ]),
        _c("div", { staticClass: "listArea" }, [
          _c("div", { staticClass: "listBox" }, [
            _c(
              "div",
              { staticClass: "boxHeader" },
              [
                _c("el-checkbox", {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "searchInput" },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-search iconStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.getEmailContactsList.apply(null, arguments)
                        }
                      }
                    }),
                    _c("el-input", {
                      on: {
                        blur: function($event) {
                          $event.stopPropagation()
                          return _vm.getEmailContactsList.apply(null, arguments)
                        }
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getEmailContactsList.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.searchKey,
                        callback: function($$v) {
                          _vm.searchKey = $$v
                        },
                        expression: "searchKey"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.loadData,
                    expression: "loadData"
                  }
                ],
                staticClass: "listCoreBox",
                attrs: {
                  "infinite-scroll-disabled": "loadDataDisabled",
                  "infinite-scroll-distance": "10",
                  "infinite-scroll-immediate-check": "false"
                }
              },
              [
                _vm.addressBookData.length != 0
                  ? _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleCheckedItemChange },
                        model: {
                          value: _vm.checkedLists,
                          callback: function($$v) {
                            _vm.checkedLists = $$v
                          },
                          expression: "checkedLists"
                        }
                      },
                      _vm._l(_vm.addressBookData, function(data) {
                        return _c(
                          "div",
                          { staticClass: "listItem" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                key: data.contactsId,
                                attrs: { label: data.contactsId }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "commonStyle nickname",
                                    attrs: { title: data.name }
                                  },
                                  [_vm._v(_vm._s(data.name))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "commonStyle email",
                                    attrs: { title: data.email }
                                  },
                                  [_vm._v("<" + _vm._s(data.email) + ">")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "placeholderMap" },
                      [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
                      1
                    )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle confirm",
              class: _vm.checkedLists.length == 0 ? "disableStyle" : "",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }