<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="560px"
    center
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :append-to-body="true"
    custom-class="emailAddressBookDialog"
    :top="'0'"
  >
    <div class="dialogBody">
      <span
        class="iconfont_Me icon-x closeDialogStyle"
        @click.stop="closeDialog"
      ></span>
      <div class="dialogHeader">Melinked {{ $t("contacts") }}</div>
      <div class="listArea">
        <div class="listBox">
          <div class="boxHeader">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            ></el-checkbox>
            <div class="searchInput">
              <span
                class="iconfont_Me icon-search iconStyle"
                @click.stop="getEmailContactsList"
              ></span>
              <el-input
                v-model="searchKey"
                @keyup.native.enter="getEmailContactsList"
                @blur.stop="getEmailContactsList"
              ></el-input>
            </div>
          </div>
          <div
            class="listCoreBox"
            v-infinite-scroll="loadData"
            infinite-scroll-disabled="loadDataDisabled"
            infinite-scroll-distance="10"
            infinite-scroll-immediate-check="false"
          >
            <el-checkbox-group
              v-model="checkedLists"
              @change="handleCheckedItemChange"
              v-if="addressBookData.length != 0"
            >
              <div class="listItem" v-for="data in addressBookData">
                <el-checkbox :label="data.contactsId" :key="data.contactsId">
                  <span class="commonStyle nickname" :title="data.name">{{
                    data.name
                  }}</span>
                  <span class="commonStyle email" :title="data.email"
                    ><{{ data.email }}></span
                  >
                </el-checkbox>
              </div>
            </el-checkbox-group>
            <div class="placeholderMap" v-else>
              <PlaceholderMap :placeholderType="2"></PlaceholderMap>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div
          class="buttonStyle confirm"
          v-waves
          @click.stop="confirm"
          :class="checkedLists.length == 0 ? 'disableStyle' : ''"
        >
          <span class="iconfont_Me icon-check iconStyle"></span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getEmailContactsList } from "@/api/im";
import PlaceholderMap from "@/newVersion/components/placeholderMap";
export default {
  name: "EmailAddressBookDialog",
  components: {
    PlaceholderMap,
  },
  computed: {
    dialogVisible() {
      return this.$store.state.melinked_email.emailAddressBookDialog;
    },
    listType() {
      return this.$store.state.melinked_email.addressBookDialogType;
    },
  },
  data() {
    return {
      searchKey: "",
      checkAll: false,
      isIndeterminate: false,
      checkedLists: [],
      addressBookData: [],
      page: 1,
      pageSize: 20,
      loadDataDisabled: false,
    };
  },
  mounted() {
    this.getEmailContactsList();
  },
  methods: {
    async loadData() {
      this.page++;
      this.loadDataDisabled = true;
      await this.getEmailContactsList();
      this.loadDataDisabled = false;
    },
    confirm() {
      const selectedItem = this.addressBookData.filter((item) => {
        return this.checkedLists.includes(item.contactsId);
      });
      if (selectedItem.length == 0) {
        return;
      }
      // 判断是添加收件人还是抄送人
      this.$store.commit("setSendModuleSelectedItems", selectedItem);
      this.closeDialog();
    },
    async getEmailContactsList() {
      const result = await getEmailContactsList({
        page: this.page,
        pageSize: this.pageSize,
        searchWords: this.searchKey,
      });
      if (result.code == 200) {
        if (this.page == 1) {
          this.addressBookData = result.data.data;
        } else {
          if (result.data.data.length > 0) {
            this.addressBookData = this.addressBookData.concat(
              result.data.data
            );
          } else {
            this.page--;
          }
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async closeDialog() {
      Object.assign(this.$data, this.$options.data.call(this));
      await this.$nextTick();
      this.$store.commit("setEmailAddressBookDialog", false);
    },
    handleCheckAllChange(val) {
      this.checkedLists = val
        ? this.addressBookData.map((item) => {
            return item.contactsId;
          })
        : [];
      this.isIndeterminate = false;
    },
    handleCheckedItemChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.addressBookData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.addressBookData.length;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.emailAddressBookDialog
  .dialogBody
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .closeDialogStyle
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
      font-size: 20px;
      cursor: pointer;
    .dialogHeader
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #000000;
      padding-top: 36px;
      user-select: none;
      flex-shrink: 0;
    .listArea
      padding: 24px;
      box-sizing: border-box;
      flex: 1;
      min-height: 0;
      width: 100%;
      .listBox
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #B3B3B3;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .boxHeader
          width: 100%;
          height: 50px;
          flex-shrink: 0;
          box-sizing: border-box;
          border-bottom: 1px solid #B3B3B3;
          padding: 0 8px 0 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .searchInput
            width: 160px;
            height: 32px;
            border-radius: 16px;
            box-sizing: border-box;
            border: 1px solid #D9D9D9;
            background: #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .iconStyle
              font-size: 16px;
              color: #8F8F8F;
              margin-left: 8px;
              cursor: pointer;
              flex-shrink: 0;
            :deep .el-input
              height: 100%;
              flex: 1;
              min-width: 0;
              margin-right: 8px;
              .el-input__inner
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 8px;
                background: transparent;
        .listCoreBox
          flex: 1;
          min-height: 0;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          scrollbar-light();
          .placeholderMap
            width: 100%;
            height: 100%;
            overflow: hidden;
          .listItem
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            border-bottom: 1px solid #F0F0F0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
    .footer
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .disableStyle
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
      .buttonStyle
        height: 100%;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #B3B3B3;
        cursor: pointer;
        .iconStyle
          font-size: 20px;
          color: #B3B3B3;
      .confirm
        background: #F39945;
        border: none;
        .iconStyle
          color: #FFFFFF;
</style>
<style lang="stylus">
.emailAddressBookDialog
  padding: 0;
  border-radius: 5px;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    height: 650px;
    max-height: 90vh;
    .listArea
      .boxHeader
        .is-focus
          .el-checkbox__inner
            border-color: #292D32  !important;;
        .el-checkbox__inner
          border: 2px solid #292D32;
          width: 16px;
          height: 16px;
          &:hover
            border-color: #f39945 !important;;
        .is-checked,.is-indeterminate
          .el-checkbox__inner
            border-color: #f39945  !important;;
        .is-checked,.is-indeterminate
          .el-checkbox__inner
            background-color: #f39945;
      .listCoreBox
        .listItem
          .el-checkbox
            width: 100%;
            height: 100%;
            position: relative;
            .is-focus
              .el-checkbox__inner
                border-color: #B3B3B3  !important;
            .el-checkbox__inner
              &:hover
                border-color: #f39945 !important;
            .is-checked
              .el-checkbox__inner
                border-color: #f39945 !important;
            .is-checked
              .el-checkbox__inner
                background-color: #f39945;
            .el-checkbox__input
              width: 16px;
              height: 16px;
              overflow: hidden;
              position: absolute;
              z-index: 1;
              top: 50%;
              left: 24px;
              transform: translate3d(0,-50%,0);
              .el-checkbox__inner
                border: 2px solid #B3B3B3;
                width: 16px;
                height: 16px;
            .el-checkbox__label
              width: 100%;
              box-sizing: border-box;
              height: 100%;
              padding: 0 8px 0 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              .commonStyle
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 200px;
                font-size: 14px;
              .nickname
                color: #000000;
              .email
                color: #B3B3B3;
</style>
